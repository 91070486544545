import Image1 from './../../../../assets/images/clientes/1.jpg';
import Image2 from './../../../../assets/images/clientes/2.jpg';
import Image3 from './../../../../assets/images/clientes/3.jpg';
import Image4 from './../../../../assets/images/clientes/4.jpg';
import Image5 from './../../../../assets/images/clientes/5.jpg';
import Image6 from './../../../../assets/images/clientes/6.jpg';
import Image7 from './../../../../assets/images/clientes/7.jpg';
import Image8 from './../../../../assets/images/clientes/8.jpg';
import Image9 from './../../../../assets/images/clientes/9.jpg';
import Image10 from './../../../../assets/images/clientes/10.jpg';
import Image11 from './../../../../assets/images/clientes/11.jpg';
import Image12 from './../../../../assets/images/clientes/12.jpg';
import Image13 from './../../../../assets/images/clientes/13.jpg';
import Image14 from './../../../../assets/images/clientes/14.jpg';
import Image15 from './../../../../assets/images/clientes/15.jpg';
import Image16 from './../../../../assets/images/clientes/16.jpg';
import Image17 from './../../../../assets/images/clientes/17.jpg';

export default {
  clients: [
    {
      title: 'PARTNERS',
      image: Image1,
      link: '',
    },
    {
      title: 'PARTNERS',
      image: Image2,
      link: '',
    },
    {
      title: 'PARTNERS',
      image: Image3,
      link: '',
    },
    {
      title: 'PARTNERS',
      image: Image4,
      link: '',
    },
    {
      title: 'PARTNERS',
      image: Image5,
      link: '',
    },
    {
      title: 'PARTNERS',
      image: Image6,
      link: '',
    },
    {
      title: 'PARTNERS',
      image: Image7,
      link: '',
    },
    {
      title: 'PARTNERS',
      image: Image8,
      link: '',
    },
    {
      title: 'PARTNERS',
      image: Image9,
      link: '',
    },
    {
      title: 'PARTNERS',
      image: Image10,
      link: '',
    },
    {
      title: 'PARTNERS',
      image: Image11,
      link: '',
    },
    {
      title: 'PARTNERS',
      image: Image12,
      link: '',
    },
    {
      title: 'PARTNERS',
      image: Image13,
      link: '',
    },
    {
      title: 'PARTNERS',
      image: Image14,
      link: '',
    },
    {
      title: 'PARTNERS',
      image: Image15,
      link: '',
    },
    {
      title: 'PARTNERS',
      image: Image16,
      link: '',
    },
    {
      title: 'PARTNERS',
      image: Image17,
      link: '',
    },
  ],
}
