import React from 'react';
import uuid from "react-uuid";
import { Oval } from 'svg-loaders-react';

import PublicationItem from './../../../../components/PublicationItem';

const Blog = (props) => {
  const publications  = props.publications;

  return (
    <section className="publications__blog">
      <div className="blog__header">
        <div className="header__title">
          <h2>Publicaciónes</h2>
        </div>
      </div>

      {
        (publications.length <= 0)
          ? <Oval />
          : <section className="blog__publications">
            {
              publications.map(publication => (
                <PublicationItem publication={publication} key={uuid()} />
              ))
            }
          </section>
      }
    </section>
  )
};

export default Blog;
