import React from 'react';
import { Oval } from 'svg-loaders-react'

class ContactForm extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      message: '',
      fetching: false,
      success: null,
      fail: null,
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  onInputChange(event) {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    event.preventDefault()

    const receiverEmail = 'web-contact@assesorum.com';
    const template = 'template_rW10fI8B';
    const user = 'user_3sO8DptxtmwRa52BX8aXr';

    this.sendFeedback(
      template,
      this.state.email,
      this.state.name,
      receiverEmail,
      this.state.message,
      user
    )

    this.setState({
      formSubmitted: true,
      fetching: true,
    })
  }

  sendFeedback (templateId, senderEmail, senderName, receiverEmail, feedback, user) {
    window.emailjs.send(
      'default_service', // default email provider in your EmailJS account
      templateId,
      {
        senderEmail,
        receiverEmail,
        feedback,
        senderName
      },
      user
    )
      .then(res => {
        this.setState({
          success: true
        })
      })
      // Handle errors here however you like, or use a React error boundary
      .catch(err => {
        this.setState({
          fail: true
        })
      })
      .finally(() => {
        this.setState({
          fetching: false,
        })
      })
  }

  render() {

    return (
      <section className="contact-form" id="contacto">
        <div className="contact-form__container">
          <h2>Contacto</h2>
          <p>Completa el siguiente formulario para dejarnos tu consulta, te contestaremos a la brevedad.</p>

          <form className="contact-form__form" onSubmit={this.handleSubmit}>
            <div className="contact-form__input-group">
              <span>Nombre y apellido</span>
              <input onChange={this.onInputChange} type="text" name="name" placeholder="Ingrese su nombre y apellido" />
            </div>
            <div className="contact-form__input-group">
              <span>Email</span>
              <input onChange={this.onInputChange} type="email" name="email" placeholder="Ingrese su email" />
            </div>
            <div className="contact-form__input-group">
              <span>Mensaje</span>
              <textarea onChange={this.onInputChange} name="message" placeholder="Escriba su mensaje" />
            </div>
            <button className="button-default">
              {
                this.state.fetching
                  ? <div>Enviando <Oval /></div>
                  : 'Enviar consulta'
              }
            </button>

            {
              this.state.success && <p className="form__message--success">La consulta se envio correctamente.</p>
            }
            {
              this.state.fail && <p className="form__message--fail">Hubo un error al enviar la consulta.</p>
            }
          </form>
        </div>
      </section>
    )
  }
}

export default ContactForm;
