import React from 'react';

import Slider from './../../components/Slider';
import Blog from './components/Blog';
import getRowId from "../../utils/functions/getRowId";
import ROUTES from "../../utils/constants/routes";

const Home = props => {
  const { publications } = props;

  const publicationSliderContent = (sliders) => {
    return sliders.map(slider => {
      const id = getRowId(slider['Marca temporal']);
      return {
        image: slider.image,
        title: slider.title,
        subtitle: slider.description,
        button: 'Ver nota completa',
        link: `${ROUTES.PUBLICATIONS}/${id}`
      }
    })
  };

  return (
    <div className="home">
      <Slider sliders={publicationSliderContent(publications)} />
      <Blog publications={publications} />
    </div>
  );
};

Home.defaultProps = {
  publications: []
};

export default Home;
