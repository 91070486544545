import Image1 from './../../assets/images/ourus.jpg';

export default {
  sliders: [
    {
      title: 'Quiénes somos',
      subtitle: '',
      image: Image1
    }
  ]
}
