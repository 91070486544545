import React from 'react';
import { Link } from "react-router-dom";

import locales from './wordings';
import logo from './../../assets/images/logo.png';
import TopBar from './components/TopBar';
import Hamburger from './components/Hamburger';
import Menu from './components/Menu';

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: locales.menu
    };

    this.handleItem = this.handleItem.bind(this);
  }

  componentDidMount() {
    const { menu } = this.state;

    const newMenu = menu.map(item => {
      const hasChildren = (item.children && item.children.length > 0);
      const newItem = hasChildren && {...item, children: item.children.map(subitem => ({ ...subitem, active: false }))};

      return hasChildren ? {...newItem} : item ;
    });

    this.setState({
      menu: newMenu,
    });
  }

  handleItem(index, childrenIndex = null) {
    const { menu } = this.state;
    const newMenu = menu.map((item, itemIndex) => {
      if (index === itemIndex) {
        if (childrenIndex != null) {
          item.children.forEach((children, indexChildren) => {
            if (childrenIndex === indexChildren) {
              children.active = !children.active;
            }
          })
        } else {
          item.active = !item.active;
        }
      }
      return item;
    });

    this.setState({
      menu: newMenu,
    })
  }

  render() {
    const { menu } = this.state;
    const { socialNetworks } = this.props;

    return (
      <header>
        <TopBar socialNetworks={socialNetworks} />
        <nav className="header__nav">
          <Link to="/">
            <img src={logo} alt="Assesorum logo" className="header__logo" />
          </Link>
          <Hamburger />
          <Menu menu={menu} handleItem={this.handleItem} />
        </nav>
      </header>
    )
  }
}

export default Header;
