import React from 'react';
import ReactHtmlParser from 'react-html-parser'

function formatText(text) {
  return text.replace(/\n/g, "<br />");
}

const Content = props => (
  <article className="service__content">
    <section>
      <h1>{props.content.title}</h1>
      <p>{ReactHtmlParser(formatText(props.content.text))}</p>
    </section>
  </article>
);

export default Content;
