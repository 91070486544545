import ROUTES from './../../utils/constants/routes';
import SERVICES_ROUTES from './../../utils/constants/services/routes';

export default {
  menu: [
    SERVICES_ROUTES,
    {
      name: 'Industrias',
      path: '/industria',
      children: [
        {
          name: 'Seguros',
          path: '/seguros',
        },
        {
          name: 'Reaseguros',
          path: '/reaseguros',
        },
        {
          name: 'Brokerage de seguros',
          path: '/brokerage-de-seguros',
        },
        {
          name: 'Brokerage de reaseguros',
          path: '/brokerage-de-reaseguros',
        },
        {
          name: 'Sindicatos',
          path: '/sindicatos',
        },
        {
          name: 'Concecionarias de autos',
          path: '/concecionarias-de-autos',
        },
        {
          name: 'Cooperativas y mutuales',
          path: '/cooperativas-y-mutuales',
        },
        {
          name: 'Mercado de Asistencia',
          path: '/mercado-de-asistencia',
        },
        {
          name: 'Tarjetas de crédito y billeteras virtuales',
          path: '/tarjetas-de-credito-y-billeteras-virtuales',
        },
        {
          name: 'Salud',
          path: '/salud',
        },
      ]
    },
    {
      name: 'Capacitación',
      path: '/capacitacion',
      children: [
        {
          name: 'AML / PLAFT',
          path: '/aml-plaft',
        },
        {
          name: 'Prevención del fraude',
          path: '/prevencion-del-fraude'
        },
        {
          name: 'Seguros',
          path: '/seguros'
        },
        {
          name: 'Reaseguros',
          path: '/reaseguros'
        },
        {
          name: 'Inducción',
          path: '/induccion'
        },
        {
          name: 'Ventas',
          path: '/ventas'
        },
        {
          name: 'Planificación Estratégica',
          path: '/planificacion-estrategiaca'
        },
        {
          name: 'Desarrollo Humano',
          path: '/desarrollo-humano'
        },
        {
          name: 'Gestión de Riesgo Crediticio',
          path: '/gestion-de-riesgo-crediticio'
        },
        {
          name: 'Gobierno Corporativo',
          path: '/gobierno-corporativo'
        },
        {
          name: 'Control Interno',
          path: '/control-interno'
        },
      ]
    },
    {
      name: 'Partners',
      link: ROUTES.PARTNERS,
      children: []
    },
    {
      name: 'Clientes',
      link: ROUTES.CLIENTS,
    },
    {
      name: 'Contactenos',
      ancle: '#contacto',
    },
    {
      name: 'Quiénes somos',
      link: ROUTES.OUR_US,
    },
  ],
}
