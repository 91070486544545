import React from 'react';
import { Oval } from 'svg-loaders-react'
import uuid from "react-uuid";

const Partners = (props) => {
  const partners  = props.partners;

  const getDriveImages = function (image = '') {
    if (image.indexOf('drive.google') !== -1) {
      let driveId = image.split('id=')[1];
      return `https://drive.google.com/uc?export=view&id=${driveId}`;
    } else {
      return image;
    }
  };

  return (
    <section className="home__blog">
      <div className="blog__header">
        <div className="header__title">
          <h2>Partners</h2>
        </div>
      </div>
      {
        (partners.length <= 0)
          ? <Oval />
          : <ul className="partners__list">
            {
              partners.map(partner => (
                <ol key={uuid()}>
                  <figure>
                    <img src={getDriveImages(partner.image)} alt={partner.name}/>
                  </figure>
                  <h2>{partner.name}</h2>
                  <h3>{partner.training}</h3>
                  <h4><span>{partner.experience} años</span> de experiencia</h4>
                  <h5>{partner.organization}</h5>
                  <p><span>Sector de Expertise:</span> <br/> {partner.expertise}</p>
                  <p><span>Competencias Profesionales:</span> <br/> {partner.competences}</p>
                  <p><span>Funciones desempeñadas en otras Organizaciones:</span> <br/> {partner.performed_tasks}</p>
                  <p><span>Email:</span> <br/> {partner.email}</p>
                  <p><span>Teléfono:</span> <br/> {partner.phone}</p>
                  {
                    partner.linkedin && <ul className="partner__networks">
                      <li>
                        <a href={partner.linkedin}><i className="fa fa-linkedin"/></a>
                      </li>
                    </ul>
                  }

                </ol>
              ))
            }
          </ul>
      }
    </section>
  )
};

export default Partners;
