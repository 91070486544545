import React from 'react';

import Slider from './../../components/Slider';
import Clients from './components/Clients';

import Locales from './wordings';

const ClientsPage = props => {
  const { sliders } = Locales;

  return (
    <div className="partners">
      <Slider sliders={sliders} />
      <Clients />
    </div>
  );
};

export default ClientsPage;
