import Image1 from './../../assets/images/clients.jpg';

export default {
  sliders: [
    {
      title: 'Clientes',
      subtitle: '',
      image: Image1
    }
  ]
}
