import Cooperativas from './../../../assets/images/services/cooperativas.jpg';
import Asistencia from './../../../assets/images/services/asistencia.jpg';

export default {
  'colocador-de-productos-de-asistencia-en-grandes-colectivos-mutuales-y-cooperativas': {
    sliders: [{
      title: 'Colocador de productos de asistencia en grandes colectivos: mutuales y cooperativas',
      image: Cooperativas
    }],
    content: {
      title: 'Colocador de productos de asistencia en grandes colectivos: mutuales y cooperativas',
      text: 'Se entiende por asistencia a la prestación específica de un conjunto de servicios, ' +
        'brindados por empresas especializadas, que entre los más comunes están la asistencia al ' +
        'vehículo, la asistencia al viajero, la asistencia al hogar u otros servicios complementarios ' +
        'de coberturas de salud.\n\n' +
        'Otros servicios de Asistencia ligados a la salud que creemos que se deben desarrollar.\n\n' +
        'Se pueden dividir en salud y Tercera Edad:\n' +
        '1. salud\n' +
        'a. Gastos Médicos y hospitalarios.\n' +
        'b. Seguimiento de Patologías crónicas.\n' +
        'c. Odontología de urgencia.\n' +
        'd. Traslados sanitarios.\n' +
        'e. repatriaciones hospitalarias y funerarias.\n' +
        'f. Segunda opinión médica (nacional o internacional).\n' +
        'g. Gestión y Coordinación de sepelios.\n' +
        'h. Cuidados Prolongados.\n' +
        'i. Área Médica Protegida.\n' +
        'j. Emergencias Médicas (Código Rojo, Verde o Amarillo).\n' +
        'k. Recordatorio de toma de medicamentos.\n' +
        'l. Conexiones con centros de salud especializados.\n' +
        'm. Farmacias 24 hs.\n' +
        'n. Delivery de farmacias.\n' +
        'o. Traslado y estancia de familiares, gastos de hotel por convalecencias);\n' +
        'b) Tercera edad:\n' +
        'a. Recordatorio toma de medicamentos.\n' +
        'b. Segunda opinión médica nacional.\n' +
        'c. Información sobre centros de salud.\n' +
        'd. Recomendaciones de médicos y centros de salud.\n' +
        'e. Transmisión de mensajes urgentes.\n' +
        'f. Asesoramiento legal en trámites jubilatorios.\n' +
        'g. Reservaciones y compras de tickets para teatros.\n' +
        'h. Información sobre centros culturales.\n' +
        'i. Baby Sitting para ancianos.\n\n' +
        'Esta es una sólo una lista enunciativa de servicios que a través de nuestra empresa\n' +
        'pueden suscribir empresas u organizaciones con grandes colectivos de personas.'
    },
  },
  'asistencia-en-la-busqueda-del-mejor-producto-y-empresa-de-asistencia': {
    sliders: [{
      title: '',
      image: Asistencia
    }],
    content: {
      title: '',
      text: ''
    },
  },
  'asesoramiento-a-empresas-de-asistencia': {
    sliders: [{
      title: 'Asesoramiento a empresas',
      image: Asistencia
    }],
    content: {
      title: 'Asesoramiento a empresas',
      text: 'Desde Assesorum tenemos equipo para asesorar a Cooperativas, Mutuales, Sindicatos,\n' +
        'Municipalidades y otros grandes concentradores de colectivos de personas. A través de\n' +
        'los servicios de asistencia se puede dar beneficios a estos colectivos.\n' +
        'A través de la oferta de estos servicios para la salud y la tercera edad.\n' +
        'Consulte sobre los mejores beneficios.'
    },
  },
}
