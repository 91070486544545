import React from 'react';

import Slider from './../../components/Slider';
import Content from './components/Content';

import Locales from './wordings';

class Services extends React.Component {
  render () {
    console.log('path', window.location.pathname.split( '/' ))
    const path = window.location.pathname.split( '/' );
    const service = Locales[path[2]];
    const children = path[3];
    console.log("aa");
    console.log(path);
    console.log(service);
    console.log(children);

    return (
      <div className="home">
        <Slider sliders={service[children].sliders} />
        <Content content={service[children].content} />
      </div>
    );
  }
}

export default Services;
