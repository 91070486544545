import React from 'react';

import Slider from './../../components/Slider';
import Blog from './components/Blog';

import Locales from './wordings';

const Publications = props => {
  const { sliders } = Locales;
  const { publications } = props;

  return (
    <div className="home">
      <Slider sliders={sliders} />
      <Blog publications={publications} />
    </div>
  );
};

export default Publications;
