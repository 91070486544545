import React from 'react';
import logo from './../../../../assets/images/logo.png';

const OurUs = () => (
  <section className="our-us__container">
    <figure>
      <img src={logo} alt="Assesorum logo"/>
    </figure>
    <article>
      <h2>Quiénes somos</h2>
      <p>
        Assesorum es una empresa de Consultoría que nació en setiembre 2006 <b>con el
        objetivo</b> de ASESORAR en la gestión empresarial, pensando en brindar
        <strong>Soluciones Integrales</strong>, para todas las áreas críticas que conforman una
        organización. Nuestro desafío es maximizar la eficiencia del negocio de nuestros
        clientes desde el lugar donde se inicie su dificultad. <br/><br/>
        Hoy nuestra empresa participa de una Red Profesional de Prestadores que incluyen:
        médicos, actuarios, contadores, economistas, abogados, ingenieros especialistas en
        higiene y seguridad. Todos ellos con una larga y reconocida carrera en los
        mercados de seguros y salud. <br/><br/>
        En definitiva, somos un equipo de profesionales de trabajo listos para armar equipo
        con nuestros clientes.
      </p>
    </article>
    <article>
      <h2>Nuestra Misión</h2>
      <p>
        Ser pioneros en el desarrollo de soluciones para el mercado asegurador y
        reasegurador latinoamericano, a partir de una gestión personal con cada cliente.
      </p>
    </article>
    <article>
      <h2>Nuestra visión</h2>
      <p>
        Proveer soluciones a nuestros clientes, individuos y empresas, a partir de una
        gestión de los recursos financieros en cantidad, calidad y precio adecuados. Esto
        implica tener una predisposición a atender y escuchar al cliente en busca de
        satisfacer sus necesidades, sus deseos, eliminar sus preocupaciones y solucionar
        sus problemas. Lograr su lealtad y su permanencia con nosotros a partir de la
        gestión personalizada.
      </p>
    </article>
    <article>
      <h2>Nuestra gente - Valor agregado</h2>
      <p>
        En estos años de desarrollo profesional hemos logrado con éxito el armado y
        lanzamiento de una aseguradora de riesgos del trabajo y de una aseguradora de
        riesgos patrimoniales en el mercado local. Además, hemos diseñado y optimizado
        procesos de aseguradoras y reaseguradoras. Hemos publicado artículos en Buenos
        Aires Económico y capacitamos en promedio a más de 1.300 personas por año de
        manera presencial “in Company” o de manera virtual.
        Hemos habilitado un sistema de gestión de aprendizaje administrado en la nube o
        sea un campus virtual para la capacitación “e-learning”
        Hemos actuado en el Mercado Latinoamericano. Hemos logrado con éxito el start-
        up de una aseguradora en Santiago de Chile, hemos capacitado y entrenado
        aseguradoras en Asunción del Paraguay y Montevideo, Uruguay. Hemos analizado
        procesos para una aseguradora en Brasil. <br/><br/>

        Apostamos al futuro y a la tecnología, y por ello, tenemos profesionales capacitados
        en herramientas de marketing digital que permitirían a las aseguradoras potenciar
        el rendimiento de su cartera en apoyo de su red comercial.
        Estos logros han sido posibles gracias a un equipo de profesionales
        multidisciplinarios con capacidad de adaptación e innovación. Hemos desarrollado
        nuestras carreras en empresas nacionales e internacionales de primera línea.
        Sustentamos nuestra labor en la premisa de: “comprender e interpretar en equipo,
        las necesidades del cliente”, sumado a nuestro conocimiento técnico orientado a
        resultados, con el fin de elaborar soluciones creativas y personalizadas.
        Trabajamos en crear un ambiente de trabajo propicio para el desarrollo humano y
        profesional, valorando que la calidad de los servicios que ofrecemos y las soluciones
        creadas sean el sello proveniente de las capacidades técnicas y humanas de cada
        miembro de nuestro equipo de profesionales.
      </p>
    </article>
  </section>
);

export default OurUs;
