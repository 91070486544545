import React, { Fragment } from 'react';

const Hamburger = () => (
  <Fragment>
    <input type="checkbox" id="header__hamburger-btn-action" className="header__hamburger-btn-action" />

    <label htmlFor="header__hamburger-btn-action">
      <div className="header__hamburger-btn">
        <i className="fa fa-bars bars" />
        <i className="fa fa-close close" />
      </div>
    </label>
  </Fragment>
);

export default Hamburger;
