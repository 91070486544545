import React from 'react';
import { connect } from "react-redux";

import Publication from './';

const PublicationContainer = props => (
  <Publication
    publications={props.publications}
  />
);

const mapStateToProps = state => {
  return {
    publications: state.publications,
  }
};

export default connect(mapStateToProps, {})(PublicationContainer)
