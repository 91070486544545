import React from 'react';
import PropTypes from 'prop-types';
import uuid from "react-uuid";

const Footer = props => (
  <footer>
    <section className="footer__container">
      <ol className="footer__social-networks">
        {
          props.socialNetworks.map(social => (
            <a href={social.link} key={uuid()}><li><i className={social.icon} aria-hidden="true" /></li></a>
          ))
        }
      </ol>
    </section>
    <p>Assesorum © 2020</p>
  </footer>
);

Footer.propTypes = {
  socialNetworks: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }))
};

export default Footer;
