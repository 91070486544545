import React from 'react';
import { connect } from "react-redux";

import Publications from './';

const PublicationsContainer = props => (
  <Publications
    publications={props.publications}
  />
);

const mapStateToProps = state => {
  return {
    publications: state.publications,
  }
};

export default connect(mapStateToProps, {})(PublicationsContainer)
