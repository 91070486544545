import React from 'react';
import PropTypes from 'prop-types';
import uuid from "react-uuid";
import { Link } from "react-router-dom";

import ROUTES from './../../utils/constants/routes';
import getRowId from './../../utils/functions/getRowId';

class PublicationItem extends React.Component {
  constructor(props) {
    super(props);

    this.handleRedirect = this.handleRedirect.bind(this);
  }

  handleRedirect() {
    const convertUrl = getRowId(this.props.publication['Marca temporal']);
    return `${ROUTES.PUBLICATIONS}/${convertUrl}`;
  }

  render() {
    const { publication } = this.props;

    return (
      <article className="publication-item" key={uuid()}>
        <Link to={this.handleRedirect}>
          <figure onClick={this.handleRedirect}>
            <img src={publication.image} alt={publication.title} />
          </figure>
        </Link>
        <div className="publication__content">
          <time>{publication.date}</time>
          <Link to={this.handleRedirect()}>
            <h3 onClick={this.handleRedirect}><span>Publicación:</span> {publication.title} <i className="fa fa-play" aria-hidden="true" /></h3>
          </Link>
          <p>{publication.description}</p>
          <div className="publication__button">
            <Link to={this.handleRedirect}>
              Ver más <i className="fa fa-play" aria-hidden="true" />
            </Link>
          </div>
        </div>
      </article>
    )
  }
}

PublicationItem.propTypes = {
  publication: PropTypes.shape({
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
  }),
};

export default PublicationItem;
