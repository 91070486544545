import React from 'react';

import Slider from './../../components/Slider';
import OurUs from './components/OurUs';

import Locales from './wordings';

const OurUsPage = () => {
  const { sliders } = Locales;

  return (
    <div className="our-us">
      <Slider sliders={sliders} />
      <OurUs />
    </div>
  );
};

export default OurUsPage;
