export default {
  name: 'Servicios',
  path: '/servicios',
  children: [
    {
      name: 'Administrativos',
      path: '/administrativos',
      noLink: true,
      children: [
        {
          name: 'Gobierno Corporativo',
          path: '/gobierno-corporativo',
        },
        {
          name: 'Prevención Lavado de Dinero',
          path: '/prevencion-lavado-de-dinero',
        },
        {
          name: 'Control Interno',
          path: '/control-interno',
        },
        {
          name: 'Prevención del fraude',
          path: '/prevencion-del-fraude',
        },
        {
          name: 'Manuales de Procesos',
          path: '/manuales-de-procesos',
        },
        {
          name: 'Flujogramas',
          path: '/flujogramas',
        }
      ]
    },
    {
      name: 'Gestion',
      path: '/gestion',
      noLink: true,
      children: [
        {
          name: 'Presupuesto y control presupuestario',
          path: '/presupuesto-y-control-presupuestario',
        },
        {
          name: 'Tablero de Control',
          path: '/tablero-de-control',
        },
        {
          name: 'Cash-Flow',
          path: '/cash-flow',
        },
        {
          name: 'Impacto sobre lanzamiento de nuevos productos u oficinas',
          path: '/impacto-sobre-lanzamiento-de-nuevos-productos-u-oficinas',
        },
        {
          name: 'Gestión con enfoque basado en Riesgos',
          path: '/gestion-con-enfoque-basado-rn-riesgos',
        }
      ]
    },
    {
      name: 'Consultoría',
      path: '/consultoria',
      noLink: true,
      children: [
        {
          name: 'Consultoría de procesos',
          path: '/consultoria-de-procesos',
        },
        {
          name: 'Finanzas corporativas',
          path: '/finanzas-corporativas',
        },
        {
          name: 'Servicios para compañias de seguros',
          path: '/servicios-para-companias-de-seguros',
        },
        {
          name: 'Servicios para reaseguradoras',
          path: '/servicios-para-reaseguradoras',
        },
        {
          name: 'Servicios para brókers de seguros y reaseguros',
          path: '/servicios-para-brokers-de-seguros-y-reaseguros',
        },
        {
          name: 'Consultoria en Ciberseguridad',
          path: '/consultoria-en-ciberseguridad',
        }
      ]
    },
    {
      name: 'Comerciales',
      path: '/comerciales',
      noLink: true,
      children: [
        {
          name: 'Asesoramiento en el lanzamiento de nuevos productos',
          path: '/asesoramiento-en-el-lanzamiento-de-nuevos-productos',
        },
        {
          name: 'Asesoramiento en el lanzamiento de nuevas oficinas comerciales',
          path: '/asesoramiento-en-el-lanzamiento-de-nuevas-oficinas-comerciales',
        },
        {
          name: 'Búsqueda de nuevos canales de comercialización',
          path: '/busqueda-de-nuevos-canales-de-comercializacion',
        },
        {
          name: 'Optimización de la estructura comercial',
          path: '/optimizacion-de-la-estructura-comercial',
        },
        {
          name: 'Entrenamiento de recursos propios y redes',
          path: '/entrenamiento-de-recursos-propios-y-redes',
        },
        {
          name: 'Marketing digital',
          path: '/marketing-digital',
        }
      ]
    },
    {
      name: 'Productos de Asistencia',
      path: '/asistencia',
      noLink: true,
      children: [
        {
          name: 'Colocador de productos de asistencia en grandes colectivos: mutuales y cooperativas',
          path: '/colocador-de-productos-de-asistencia-en-grandes-colectivos-mutuales-y-cooperativas',
        },
        {
          name: 'Asistencia en la búsqueda del mejor producto y empresa de asistencia',
          path: '/asistencia-en-la-busqueda-del-mejor-producto-y-empresa-de-asistencia',
        },
        {
          name: 'Asesoramiento a empresas de asistencia',
          path: '/asesoramiento-a-empresas-de-asistencia',
        },
      ]
    },
    /*{
      name: 'Paquetes de sistemas',
      path: '/paquetes-de-sistemas',
      children: [
        {
          name: 'Encuestas para aseguramiento de vida colectivo',
          path: '/encuestas-para-aseguramiento-de-vida-colectivo',
        },
        {
          name: 'Aseguramiento de vida colectivo empresa',
          path: '/aseguramiento-de-vida-colectivo-empresa',
        },
        {
          name: 'Gestión de obras sociales y sindicales',
          path: '/gestion-de-obras-sociales-y-sindicales',
        },
        {
          name: 'Campus virtural para capacitación',
          path: '/campus-virtural-para-capacitacion',
        },
      ]
    },*/
  ]
}
