import Image1 from './../../assets/images/partners.jpg';

export default {
  sliders: [
    {
      title: 'Partners',
      subtitle: '',
      image: Image1
    }
  ]
}
