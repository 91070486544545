import React from 'react';
import './assets/scss/index.scss';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import { connect } from "react-redux";
import Tabletop from "tabletop";
import uuid from "react-uuid";

// CONSTANTS
import ROUTES from './utils/constants/routes';
import SOCIAL_NETWORKS from './utils/constants/social-networks';
import { WHATSAPP } from './utils/constants/whatsapp';

// Pages
import Home from './pages/home/container';
import Publications from './pages/publications/container';
import Publication from './pages/publications/components/Publication/container';
import Services from './pages/services';
import Training from './pages/training';
import Industry from './pages/industry';
import Partners from './pages/partners/container';
import Clients from './pages/clients';
import OurUs from './pages/our-us';
import Unavailable from './pages/unavailable';

// Components
import Header from './components/Header';
import Footer from './components/Footer';
import ContactForm from './components/ContactForm';
import WhatsApp from './components/WhatsApp';

// Redux
import { types } from './ducks/Publications';

class App extends React.Component {

  componentDidMount() {
    Tabletop.init({
      key: 'https://docs.google.com/spreadsheets/d/1hR_xoU1B4hlLYgC_uXgvg5CX1Yc55EVH85FoSdvw-YA/pubhtml',
      headers: false,
      callback: data => {
        this.props.setPublications(data.blog.elements);
        this.props.setPartners(data.partners.elements);
      },
    })
  }

  render() {
    return (
      <Router>
        <Header socialNetworks={SOCIAL_NETWORKS} />

        <Switch>
          <Route exact path={ROUTES.HOME} component={Home} />
          <Route exact path={ROUTES.PARTNERS} component={Partners} />
          <Route exact path={ROUTES.CLIENTS} component={Clients} />
          <Route exact path={ROUTES.OUR_US} component={OurUs} />
          <Route exact path={ROUTES.PUBLICATIONS} component={Publications} />
          <Route exact path={ROUTES.PUBLICATION} component={Publication} />
          <Route exact path={ROUTES.ERROR} component={Unavailable} />
          <Route path={ROUTES.SERVICES} component={Services}/>
          <Route path={ROUTES.TRAINING} component={Training}/>
          <Route path={ROUTES.INDUSTRY} component={Industry}/>
          <Route component={Unavailable} />
        </Switch>

        <WhatsApp number={WHATSAPP} />
        <ContactForm />
        <Footer socialNetworks={SOCIAL_NETWORKS} />
      </Router>
    );
  }
}

const mapStateToProps = state => {
  return {
    publications: state.publications,
    partners: state.partners,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    setPublications: (publications) => dispatch({type: types.PUBLICATIONS_SET, publications: publications}),
    setPartners: (partners) => dispatch({type: types.PARTNERS_SET, partners: partners}),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
