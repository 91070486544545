import Image1 from './../../assets/images/slider/slider-1.jpg';

export default {
  sliders: [
    {
      title: 'BDO: 167 países, una organización globalmente conectada',
      subtitle: 'Organizados de forma única para ofrecer un servicio excepcional, siempre y en cualquier lugar.',
      button: '¿Por qué elegirnos?',
      image: Image1
    }
  ]
}
