import AML from "../../assets/images/training/aml.jpg";
import Seguros from "../../assets/images/services/compania-seguros.jpg";
import Reaseguros from "../../assets/images/services/companias-reaseguradoras.jpg";
import Fraude from "../../assets/images/training/prevencion-fraude.jpg";
import Induccion from "../../assets/images/training/induccion.jpg";
import Ventas from "../../assets/images/training/ventas.jpg";
import Planificacion from "../../assets/images/training/planificacion-estrategiaca.jpg";
import DesarrolloHumano from "../../assets/images/training/desarrollo-humano.jpg";
import RiesgoCrediticio from "../../assets/images/training/riesgo-creditici.jpg";
import GobiernoCorporativo from "../../assets/images/services/gobierno-corporativo.jpg";
import ControlInterno from "../../assets/images/services/control-interno.jpg";

export default {
  'aml-plaft': {
    sliders: [{
      title: 'AML / PREVENCIÓN DEL LAVADO DE DINERO Y FINANCIAMIENTO DEL TERRORISMO',
      image: AML
    }],
    content: {
      title: 'AML / PREVENCIÓN DEL LAVADO DE DINERO Y FINANCIAMIENTO DEL TERRORISMO',
      text: 'Curso destinado a los sujetos obligados por la Ley 25.246. Las capacitaciones ' +
        'explican los beneficios de un buen sistema de prevención con enfoque basado en ' +
        'riesgos, las obligaciones del órgano de dirección y todos los procedimientos que se ' +
        'deben llevar a cabo para cumplir con las normas de la Unidad de Información ' +
        'Financiera y otros entes reguladores de la Actividad. También asesoramos en el ' +
        'diseño de matrices de riesgos con enfoque basado en riesgos para procesos y para ' +
        'control de clientes.\n\n' +
        'Somos Revisores Externos independientes aprobados por la Unidad de Información ' +
        'Financiera.'
    },
  },
  'prevencion-del-fraude': {
    sliders: [{
      title: 'PREVENCION DEL FRAUDE',
      image: Fraude
    }],
    content: {
      title: 'PREVENCION DEL FRAUDE',
      text: 'Enfocado al cumplimiento por parte de Aseguradoras y Reaseguradoras en el ' +
        'cumplimiento de la RG SSN 38.477. Las obligaciones del Órgano de Administración, ' +
        'la capacitación permanente de todos los empleados y directivos. Explicamos la ' +
        'necesidad de tener buenos sistemas de prevención que le ahorre a la empresa una ' +
        'buena suma de dinero por fraudes contra la Sociedad.'
    },
  },
  'seguros': {
    sliders: [{
      title: 'SEGUROS',
      image: Seguros
    }],
    content: {
      title: 'SEGUROS',
      text: 'Curso destinado a explicar los fundamentos en seguros. Fundamental para la ' +
        'capacitación en aseguradoras o brokers de seguros. Tenemos una gama de cursos ' +
        'que van desde lo básico y la historia del seguro hasta temas más complejos entre ' +
        'ellos.\n\n' +
        '■ Introducción al Seguro. Su historia y sus inicios.\n' +
        '- Los primeros contratos de seguros.\n' +
        '- Las tres etapas en la evolución de seguros\n' +
        '- El riesgo – características.\n' +
        '- Clases de Riesgos.\n' +
        '- Técnicas de distribución del Riesgo\n\n' +
        '■ La institución aseguradora.\n' +
        '- El seguro, objetivo y definición.\n' +
        '- El contrato de seguro y sus elementos.\n' +
        '- La vida del contrato.\n' +
        '- Clasificación general y marco regulatorio.\n\n' +
        '■ Los procesos en el negocio de seguros\n' +
        '- Gestión por Procesos\n' +
        '- Estructuras organizaciones.\n' +
        '- Modelo de una empresa de seguros.\n' +
        '- Procesos estratégicos.\n' +
        '- Como ser rentable.\n\n' +
        '■ Los productos de seguros.\n' +
        '- Seguros para personas.\n' +
        '- Seguros patrimoniales.\n' +
        '- Riesgos del Trabajo.\n' +
        '- Seguros obligatorios.\n' +
        '- El cliente como foco del negocio.\n\n' +
        '■ Gestión y Distribución del Riesgo.\n' +
        '- Riesgos y como mitigarlos.\n' +
        '- Coaseguro y Reaseguro.\n' +
        '- Principios básicos del Reaseguro.\n' +
        '- Funciones y utilidades.\n' +
        '- Contratos tipos y casos prácticos.\n\n' +
        '■ Gobierno Corporativo.\n' +
        '- Solvencia II.\n' +
        '- Las normas de la SSN al respecto.\n' +
        '- Como implementar un sistema eficiente de gobierno corporativo.\n' +
        '- Enfoque basado en riesgos.\n' +
        '- La importancia de los comités.\n\n' +
        '■ La gestión eficaz en seguros.\n' +
        '- Como implementar un presupuesto y control presupuestario eficaz.\n' +
        '- Tablero de Control su importancia.\n' +
        '- Cash Flow y política de inversión.\n' +
        '- Productos de Inversión.\n' +
        '- Ser un eficaz administrador.'
    },
  },
  'reaseguros': {
    sliders: [{
      title: 'REASEGUROS',
      image: Reaseguros
    }],
    content: {
      title: 'REASEGUROS',
      text: 'Curso destinado a explicar los fundamentos en Reaseguros. Necesario para\n' +
        'aseguradoras y brokers de reaseguros.\n\n' +
        '■ Introducción al Reaseguro.\n' +
        '- Herramienta de distribución del riesgo.\n' +
        '- Clases de Reaseguro.\n' +
        '- Tipos de Reaseguros.\n' +
        '- Aspectos contractuales.\n' +
        '- Clausulas más frecuentes.\n' +
        '- Casos Prácticos.'
    },
  },
  'induccion': {
    sliders: [{
      title: 'INDUCCIÓN',
      image: Induccion
    }],
    content: {
      title: 'INDUCCIÓN',
      text: 'Cursos destinados a la formación de la cultura empresarial de un nuevo empleado\n' +
        'al mundo de su nueva empresa. Necesario en todo tipo de organización ya que la\n' +
        'cultura organizacional tarde en comunicarse.\n\n' +
        '- Introducción a la normas y políticas de la empresa..\n' +
        '- Código de Conducta y ética.\n' +
        '- Ley Penal Empresaria.\n' +
        '- Política y Privacidad de la información.\n'
    },
  },
  'ventas': {
    sliders: [{
      title: 'VENTAS',
      image: Ventas
    }],
    content: {
      title: 'VENTAS',
      text: 'Cursos destinados a la formación de la fuerza de ventas.\n\n' +
        '- Cursos técnicos y de producto.\n' +
        '- Cursos comerciales y de canales de comercialización.\n' +
        '- Banca Assurance.\n' +
        '- Otros cursos comerciales o técnicos más específicos.\n' +
        '- Ventas en canales digitales.\n'
    },
  },
  'planificacion-estrategiaca': {
    sliders: [{
      title: 'PLANIFICACIÓN ESTRATEGIACA',
      image: Planificacion
    }],
    content: {
      title: 'PLANIFICACIÓN ESTRATEGIACA',
      text: 'Hoy más que nunca, la planificación estratégica es una herramienta infaltable en\n' +
        'cualquier organización, tanto económica como financiera. Estos cursos están\n' +
        'desarrollados para mejorar las habilidades del Órgano de Administración y de la\n' +
        'Alta Gerencia.\n\n' +
        '- Diseño de los planes de negocios requeridos por el Reglamento de la Actividad ' +
        'Aseguradora.\n' +
        '- Presupuesto y Control Presupuestario desagregados por áreas de negocios, líneas de ' +
        'productos, oficinas comerciales o zonas geográficas.\n' +
        '- Capacitación en la elaboración de herramientas de presupuestación y su seguimiento.\n' +
        '- Análisis de rentabilidad de oficinas comerciales o Sucursales en el Exterior.\n' +
        '- Información global para toma de Decisiones. Cuadros de Mandos.\n'
    },
  },
  'desarrollo-humano': {
    sliders: [{
      title: 'DESARROLLO HUMANO',
      image: DesarrolloHumano
    }],
    content: {
      title: 'DESARROLLO HUMANO',
      text: 'La planificación y el desarrollo estratégico de los recursos humanos debe ser prioritario y\n' +
        'constante para la organización. Podemos ayudarlos en esa planificación anual de sus recursos\n' +
        'y logra así un alto nivel motivacional. Los recursos humanos son los activos más importantes\n' +
        'en cualquier empresa.\n\n' +
        '- Introducción al entorno VICA\n' +
        '- Liderazgo VICA\n' +
        '- Comunicación Consciente.\n' +
        '- Metrologías agiles y aplicadas.\n' +
        '- La nueva gestión de ventas.\n'
    },
  },
  'gestion-de-riesgo-crediticio': {
    sliders: [{
      title: 'GESTIÓN DE RIESGO CREDITICIO',
      image: RiesgoCrediticio
    }],
    content: {
      title: 'GESTIÓN DE RIESGO CREDITICIO',
      text: 'Dirigido a profesionales de las áreas de riesgo de empresas: bancos públicos y privados,\n' +
        'aseguradoras, fondos de inversión y Organismos Multilaterales de Crédito.\n\n' +
        '■ Módulo I. Introducción y procedimientos básicos\n' +
        '- Definición de riesgo crediticio. Conceptos Básicos.\n' +
        '- Riesgo crediticio en tiempos normales y de crisis.\n' +
        '- Metodología de evaluación crediticia.\n' +
        '- Ratios claves.\n' +
        '- El uso de garantías.\n' +
        '- Proyección de Estados de Resultados y Cash-Flow\n' +
        '■ Módulo II: Proceso de calificación y manejo de situaciones difíciles.\n' +
        '- El Comité de Calificación, funcionamiento\n' +
        '- Preparación de materiales para la discusión.\n' +
        '- Acciones anticipatorias de una modificación a la evaluación de riesgos.\n' +
        '- Como comunicar una reducción de calificación.\n' +
        '- Algunos tips que ayudan.'
    },
  },
  'gobierno-corporativo': {
    sliders: [{
      title: 'GGOBIERNO CORPORATIVO',
      image: GobiernoCorporativo
    }],
    content: {
      title: 'GOBIERNO CORPORATIVO',
      text: 'Dirigido a empresas que deban implementar buenas prácticas y que sean supervisadas por\n' +
        'Organismos de Control como Bancos, aseguradoras, la Comisión Nacional de Valores.\n\n' +
        '■ Fundamentos del Gobierno Corporativo.\n' +
        '- Basilea II y Solvencia II.\n' +
        '- Importancia del Gobierno Corporativo.\n' +
        '- Régimen de Sociedades Cotizadas en Argentina.\n' +
        '- Enfoque basado en Riesgos.\n' +
        '■ Gobierno Corporativo requerido en Seguros.\n' +
        '- Normativa Vigente de la SSN.\n' +
        '- Requisitos y buenas prácticas.\n' +
        '- Enfoque basado en Riesgos.\n' +
        '- La importancia de la información para la Dirección y la Alta Gerencia.\n' +
        '- Publicidad oportuna y confiable.\n' +
        '- Mejora permanente.'
    },
  },
  'control-interno': {
    sliders: [{
      title: 'CONTROL INTERNO',
      image: ControlInterno
    }],
    content: {
      title: 'CONTROL INTERNO',
      text: 'Dirigido a empresas aseguradoras que deban implementar, mejorar o cambiar sus procesos\n' +
        'para que sean más eficientes. También necesario para optimizar procesos, reducir errores y\n' +
        'sentar las bases de un eficiente departamento de Control Interno y de auditoría.\n\n' +
        '■ Normativa vigente de la SSN y sus fundamentos.\n' +
        '- Pautas Mínimas y obligatorias.\n' +
        '- Responsable de Control Interno – Requisitos de la posición.\n' +
        '- Comité de Control Interno.\n' +
        '- Plan Anual de Control Interno.\n' +
        '- Informes y Libro de Actas.\n' +
        '- Como encarar una tarea eficiente – Sistemas de gestión.\n' +
        '- La detección de fraudes'
    },
  },
}
