/**
 * Default State
 */
const defaultState = {
  publications: [],
  partners: [],
};

/**
 * Types
 */
const types = {
  PUBLICATIONS_SET: 'PUBLICATIONS/SET',
  PARTNERS_SET: 'PARTNERS/SET',
};

/**
 * Reducer
 */
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.PUBLICATIONS_SET:
      return {
        ...state,
        publications: action.publications
      };
    case types.PARTNERS_SET:
      return {
        ...state,
        partners: action.partners
      };
    default:
      return state;
  }
};

export { types, reducer };
