import NuevosProductos from './../../../assets/images/services/nuevos-productos.jpg';
import OficinasComerciales from './../../../assets/images/services/oficinas-comerciales.jpeg';
import CanalesComercio from './../../../assets/images/services/canales-comercio.png';
import OptimizarComercio from './../../../assets/images/services/optimizar-comercio.jpg';
import Redes from './../../../assets/images/services/redes.jpg';
import MarketingDigital from './../../../assets/images/services/marketing-digital.jpg';

export default {
  'asesoramiento-en-el-lanzamiento-de-nuevos-productos': {
    sliders: [{
      title: 'Asesoramiento en el lanzamiento de nuevos Productos',
      image: NuevosProductos
    }],
    content: {
      title: 'Asesoramiento en el lanzamiento de nuevos Productos',
      text: 'Siempre buscando que las acciones comerciales cumplan con los lineamientos de:\n\n' +
        '■ Retorno de la inversión.\n' +
        '■ Necesidades de la comunidad.\n' +
        '■ Buena calidad, precio y\n' +
        '■ Marketing adecuado del producto / Servicio.\n\n' +
        'Entre las acciones comerciales podemos mencionar:\n\n' +
        'Nuevos productos relacionados con vida o retiro.\n' +
        'Mejora de algún producto a través de la mejora en la calidad de servicio o el complemento\n' +
        'de más servicios al mismo precio.'
    },
  },
  'asesoramiento-en-el-lanzamiento-de-nuevas-oficinas-comerciales': {
    sliders: [{
      title: 'Asesoramiento en el lanzamiento de nuevas Oficinas Comerciales',
      image: OficinasComerciales
    }],
    content: {
      title: 'Asesoramiento en el lanzamiento de nuevas Oficinas Comerciales',
      text: 'Permite la apertura de nuevas oficinas en lugares estratégicos. Se analiza en función del' +
        'producto / servicio, los canales de comercialización actuales y los potenciales a futuros.\n\n' +
        'Análisis de la estructura de viabilidad de una oficina en función al repago esperado de la ' +
        'misma medido en tiempo.\n\n' +
        'Búsqueda de alternativas de ventas a través de canales digitales.\n\n' +
        'Implementación de herramientas de medición de los nuevos canales.'
    },
  },
  'busqueda-de-nuevos-canales-de-comercializacion': {
    sliders: [{
      title: 'Búsquedas de nuevos canales de comercialización',
      image: CanalesComercio
    }],
    content: {
      title: 'Búsquedas de nuevos canales de comercialización',
      text: 'Algo que ha demostrado la nueva era es, la potencialidad infinita de un buen canal de ' +
        'comercialización digital.\n\n' +
        'Los canales de comercialización son las vías que las empresas tienden para llevar sus ' +
        'productos a sus clientes de la forma más económica, eficiente y efectiva. Es uno de los ' +
        'puntos clave dentro de la comercialización de productos y en la gestión de ventas y ' +
        'espacios comerciales Marketing digital, ventas por la web. Díganos que producto tiene y le diremos como ' +
        'comercializarlo de manera más eficiente y rápida.'
    },
  },
  'optimizacion-de-la-estructura-comercial': {
    sliders: [{
      title: 'Optimización de la estructura comercial',
      image: OptimizarComercio
    }],
    content: {
      title: 'Optimización de la estructura comercial',
      text: 'El objetivo de un proyecto de Optimización de la Estructura Organizacional es analizar y ' +
        'definir una estructura funcional acorde con la estructura general de la empresa que ' +
        'permita: Coherencia entre las ventas y los costos, entre la estructura y los recursos ' +
        'humanos, entre la esperanza de crecimiento y de rentabilidad.\n\n' +
        'Las empresas necesitan utilizar todas las herramientas de gestión para el logro de sus ' +
        'objetivos:\n\n' +
        '■ La planificación.\n' +
        '■ Planes Comerciales y de desarrollo.\n' +
        '■ Marketing digital.\n' +
        '■ Presupuesto y control Presupuestario.\n' +
        '■ Cash-Flow y\n' +
        '■ Tablero de Comando.\n' +
        '■ Planificación impositiva.'
    },
  },
  'entrenamiento-de-recursos-propios-y-redes': {
    sliders: [{
      title: 'Entrenamiento de recursos propios y redes',
      image: Redes
    }],
    content: {
      title: 'Entrenamiento de recursos propios y redes',
      text: 'Los principales objetivos de un sistema de entrenamiento son el de preparar al personal ' +
        'para la ejecución inmediata de diversas tareas en la organización, proporcionar ' +
        'oportunidades para el continuo desarrollo personal y cambiar la actitud de las personas ' +
        'para crear un ambiente de trabajo más satisfactorio. Lo mismo ocurre con las redes ' +
        'comerciales.\n\n' +
        'Las capacitaciones pueden ser presenciales o en la modalidad e-learning, para esto ' +
        'último, hemos habilitado un sistema de gestión de aprendizaje administrado en la nube, lo ' +
        'que permite una flexibilidad en las herramientas de capacitación y un gran seguimiento ' +
        'sobre los alumnos/empleados.\n\n' +
        'Cursos que se pueden desarrollar de manera presencial o en la modalidad e-learning:\n\n' +
        '1. Introducción al Seguro.\n' +
        '2. Introducción al Reaseguro.\n' +
        '3. Cursos técnicos y de producto.\n' +
        '4. Cursos comerciales y de canales de comercialización.\n' +
        '5. Banca Assurance.\n' +
        '6. Otros cursos comerciales o técnicos más específicos.\n' +
        '7. Gobierno Corporativo.\n' +
        '8. Prevención del Fraude.'
    },
  },
  'marketing-digital': {
    sliders: [{
      title: 'Marketing Digital',
      image: MarketingDigital
    }],
    content: {
      title: 'Marketing Digital',
      text: 'El marketing digital es el componente del marketing que utiliza tecnologías digitales ' +
        'basadas en Internet y en línea, como computadoras de escritorio, teléfonos móviles y otros ' +
        'medios digitales y plataformas para promocionar productos y servicio. \n\n' +
        'Hoy más que nunca, avanzar en estos canales para expandir las ventas, repensar el ' +
        'negocio y el uso de las tecnologías que además permita abaratar costos y llegar hasta ' +
        'lugares donde sería impensado llegar por otros medios.\n\n' +
        'Tener en consideración la ciberseguridad es fundamental para el bienestar de estos ' +
        'canales.'
    },
  }
}
