import React from 'react';
import { Oval } from 'svg-loaders-react'
import { Link } from "react-router-dom";
import uuid from "react-uuid";

import ROUTES from './../../../../utils/constants/routes'
import PublicationItem from './../../../../components/PublicationItem';

const Blog = (props) => {
  const publications  = props.publications.sort((a, b) => a['Marca temporal'] > b['Marca temporal'] ? 1 : -1);

  return (
    <section className="home__blog">
      <div className="blog__header">
        <div className="header__title">
          <h2>Publicación</h2>
        </div>
        <div className="header__more-button">
          <Link to={ROUTES.PUBLICATIONS}>Ver todas las publicaciones <i className="fa fa-play" aria-hidden="true" /></Link>
        </div>
      </div>

      {
        (publications.length <= 0)
          ? <Oval />
          : <section className="blog__publications">
            {
              publications.map(publication => (
                <PublicationItem publication={publication} key={uuid()} />
              ))
            }
          </section>
      }
    </section>
  )
};

export default Blog;
