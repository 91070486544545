import PresupuestoyControlPresupuestario from './../../../assets/images/services/presupuesto-y-control-presupuestario.jpg';
import TablerodeControl from './../../../assets/images/services/tablero-de-control.jpg';
import CashFlow from './../../../assets/images/services/cash-flow.jpg';
import ProyeccionesdeFondos from './../../../assets/images/services/proyeccion-fondos.jpg';
import GestionDeEnfoque from './../../../assets/images/services/gestion-de-enfoque.jpeg';

export default {
  'presupuesto-y-control-presupuestario': {
    sliders: [{
      title: 'Presupuesto y Control Presupuestario',
      image: PresupuestoyControlPresupuestario
    }],
    content: {
      title: 'Presupuesto y Control Presupuestario',
      text: 'Aún en un contexto de inflación, el presupuesto y el control presupuestario son claves. Se\n' +
        'deben buscar los mecanismos más idóneos para cada organización, pero es una\n' +
        'herramienta fundamental en la actual situación económico-financiera.\n\n' +
        'El presupuesto económico se debe desagregar a su máxima\n\n' +
        '■ Por cuenta contable.\n' +
        '■ Por mes\n' +
        '■ Por oficina.\n' +
        '■ Por producto\n' +
        '■ Por zona geográfica.\n' +
        'El control presupuestario es la clave para aprender a presupuestar y a mejorar en el\n' +
        'proceso. Permite además invertir los saldos excedentes en el presupuesto financiero o\n' +
        'Cash-Flow.'
    },
  },
  'tablero-de-control': {
    sliders: [{
      title: 'Tablero de Control',
      image: TablerodeControl
    }],
    content: {
      title: 'Tablero de Control',
      text: 'Herramienta de Gestión empresarial utilizada para conocer la evolución de la empresa y\n' +
        'conocer de manera prematura cualquier desvío y/o alarma que afecte a la organización.\n\n' +
        'Complementa al Presupuesto y Control Presupuestario y al Cash-Flow.\n\n' +
        'Normalmente se desarrolla a través de un formato visual con los clásicos colores verde,\n' +
        'amarillo y rojo de los semáforos que permite con un rápido vistazo ver como funciona las\n' +
        'operaciones claves de la empresa.'
    },
  },
  'cash-flow': {
    sliders: [{
      title: 'Cash-Flow',
      image: CashFlow
    }],
    content: {
      title: 'Cash-Flow',
      text: 'Herramienta de presupuestación financiera fundamental en cualquier contexto y entorno ' +
        'económico.\n\n' +
        'Se desarrolla de manera mensual como máximo, siendo la semana el período justo de\n' +
        'construcción dependiendo del tipo de empresa.\n\n' +
        'Permite determinar con bastante precisión la necesidad o el excedente de fondos y así ' +
        'lograr el mejor uso y optimización de los mismos. Como cualquier herramienta, se ' +
        'perfecciona con la práctica, el uso y la retro-alimentación de información.\n\n' +
        'Herramienta clave para cualquier empresa de cualquier envergadura.'
    },
  },
  'impacto-sobre-lanzamiento-de-nuevos-productos-u-oficinas': {
    sliders: [{
      title: 'Proyecciones de fondos por nuevas inversiones',
      image: ProyeccionesdeFondos
    }],
    content: {
      title: 'Proyecciones de fondos por nuevas inversiones',
      text: 'Muchas empresas quiebran por expandirse sin un verdadero plan o en proyectar las ' +
        'expectativas de repago del mismo.\n\n' +
        'Las inversiones en el lanzamiento de nuevos productos u oficinas deben ser medido con ' +
        'antelación para determinar:\n\n' +
        '■ Necesidades de capital.\n' +
        '■ Necesidad de fondos.\n' +
        '■ Necesidades de recursos: humanos, tecnológicos, inmobiliarios, otros.\n' +
        '■ Estudio de re-pago de la inversión.\n' +
        '■ Estudio sobre la competencia.'
    },
  },
  'gestion-con-enfoque-basado-rn-riesgos': {
    sliders: [{
      title: 'Gestión con Enfoque basado en Riesgos',
      image: GestionDeEnfoque
    }],
    content: {
      title: 'Gestión con Enfoque basado en Riesgos',
      text: 'La norma ISO 31000 define la Gestión de Riesgos como todas aquellas acciones ' +
        'coordinadas para dirigir y controlar los riesgos a los que puedan estar abocadas las ' +
        'organizaciones. El objetivo es trazar un marco de acción para saber qué aspectos son ' +
        'susceptibles de tener riesgos en una organización y gestionar en como mitigarlos o ' +
        'reducirlos.\n\n' +
        'El propósito de esta norma es la gestión de riesgos, la creación y la protección de valor. ' +
        'Mejorar el desempeño, fomentar la innovación y contribuye al logro de los objetivos.\n\n' +
        'Todo lo que busca las buenas prácticas de Gobierno Corporativo.'
    },
  },
}
