import React from 'react';
import uuid from "react-uuid";

const TopBar = props => (
  <div className="header__top-bar">
    <div className="top-bar__container">
      <ol className="top-bar__social-networks">
        {
          props.socialNetworks.map(social => (
            <a href={social.link} key={uuid()}><li><i className={social.icon} aria-hidden="true" /></li></a>
          ))
        }
      </ol>
      <ol className="top-bar__info">
        <li><i className="fa fa-phone" aria-hidden="true" /> (+54 11) 15-3154-4889</li>
        <li><i className="fa fa-map-marker" aria-hidden="true" /> San Martin 910 piso 5 of. B. CABA</li>
      </ol>
    </div>
  </div>
);

export default TopBar;
