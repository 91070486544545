import React from 'react';
import { Oval } from 'svg-loaders-react'
import ReactHtmlParser from 'react-html-parser';
import classNames from 'classnames';

import Slider from './../../../../components/Slider';
import getRowId from "./../../../../utils/functions/getRowId";

class Publication extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      publication: {
        image: '',
        title: '',
        description: '',
        date: '',
      }
    };
  }

  getPublication() {
    const { publications } = this.props;
    const path = window.location.pathname;
    const id = path.split(path.replace(path.split('/').pop(),''))[1];
    const publication = publications.find(item => getRowId(item['Marca temporal']) === id);

    if (!publication) {
      window.location = "/error"
    } else {
      return publication;
    }
  }

  formatText(text) {
    return text.replace(/\n/g, "<br />");
  }

  render() {
    const { publications } = this.props;
    const publication = (publications.length > 0) && this.getPublication();
    const publicationClasses = classNames('publication', { 'publication--fetching': !publication.title });
    const text = publication.text && this.formatText(publication.text);

    return (
      <section className={publicationClasses}>
        {
          !publication.title
            ? <Oval />
            : <article className="publication__article">
              <Slider publication={publication} />
              <div className="publication__container">
                <section>
                  <h1>Publicación: {publication.title}</h1>
                  <time>{publication.date}</time>
                  <p>{ReactHtmlParser(text)}</p>
                </section>
              </div>
            </article>
        }
      </section>
    )
  }
}


export default Publication;
