import React from 'react';
import uuid from "react-uuid";

import Locales from './wordings';

const Partners = () => {
  const { clients } = Locales;

  console.log(Locales)

  return (
    <section className="home__blog">
      <div className="blog__header">
        <div className="header__title">
          <h2>Clientes</h2>
        </div>
      </div>
      <ol className="clients__list">
        {
          clients.map(partner => (
            <li key={uuid()}>
              <figure>
                <img src={partner.image} alt={partner.name}/>
              </figure>
            </li>
          ))
        }
      </ol>
    </section>
  )
};

export default Partners;
