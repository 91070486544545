import React from 'react';

import Slider from './../../components/Slider';
import Partners from './components/Partners';

import Locales from './wordings';

const PartnersPage = props => {
  const { sliders } = Locales;
  const { partners } = props;

  return (
    <div className="partners">
      <Slider sliders={sliders} />
      <Partners partners={partners} />
    </div>
  );
};

PartnersPage.defaultProps = {
  partners: []
};

export default PartnersPage;
