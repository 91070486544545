import React from 'react';

import Slider from './../../components/Slider';
import Content from './components/Content';

import Locales from './wordings';

class Services extends React.Component {
  render () {
    const path = window.location.pathname.split( '/' );
    const service = Locales[path[2]];

    return (
      <div className="home">
        <Slider sliders={service.sliders} />
        <Content content={service.content} />
      </div>
    );
  }
}

export default Services;
