import ConsultoriaProcesos from './../../../assets/images/services/consultoria-procesos.jpg';
import FinanzasCorporativas from './../../../assets/images/services/finanzas-corporativas.jpg';
import CompaniaSeguros from './../../../assets/images/services/compania-seguros.jpg';
import CompaniaReaseguradora from './../../../assets/images/services/companias-reaseguradoras.jpg';
import BrokersSeguros from './../../../assets/images/services/brokers-seguros.jpg';
import CiberSeguridad from './../../../assets/images/services/ciberseguridad.jpg';

export default {
  'consultoria-de-procesos': {
    sliders: [{
      title: 'Consultoría de Procesos',
      image: ConsultoriaProcesos
    }],
    content: {
      title: 'Consultoría de Procesos',
      text: 'Permite evaluar la eficacia de los procesos actuales en una compañía. La consultoría no ' +
        'solo consiste en la revisión, sino en definir oportunidades de mejora y posibles soluciones. \n\n' +
        'Los tiempos cambian, las personas cambian y la forma de hacer negocios también. \n\n' +
        'Esta consultoría permite detectar deficiencias, excesos de procesos, falta de personal y ' +
        'problemas de calidad.\n\n' +
        'Se complementa con diagramas de flujos y sugerencias de mejoras o incorporación de\n' +
        'nuevas y mejores prácticas y mediciones.'
    },
  },
  'finanzas-corporativas': {
    sliders: [{
      title: 'Finanzas Corporativas',
      image: FinanzasCorporativas
    }],
    content: {
      title: 'Finanzas Corporativas',
      text: 'Es un área que se centra en las decisiones monetarias que hacen las empresas, así como ' +
        'en las herramientas y los análisis utilizados para tomar esas decisiones. El principal ' +
        'objetivo de las finanzas corporativas es maximizar el valor para el accionista.\n\n' +
        'Los Gerentes de Finanzas deben buscar herramientas de maximización de las utilidades ' +
        'pero siguiendo los lineamientos establecidos previamente en función a su perfil de ' +
        'inversor.\n\n' +
        'Debe utilizar herramientas como el Presupuesto y Control presupuestario, el Tablero de ' +
        'Comando y el Cash-Flow teniendo cuidado de que las inversiones no sean riesgosas y ' +
        'tengo la “duration” adecuada en relación con sus pasivos a corto, mediano y largo plazo.'
    },
  },
  'servicios-para-companias-de-seguros': {
    sliders: [{
      title: 'Servicios para compañías de Seguros',
      image: CompaniaSeguros
    }],
    content: {
      title: 'Servicios para compañías de Seguros',
      text: 'Hemos desarrollado una variada gama de servicios para estas empresas. En todos los ' +
        'casos, brindamos un servicio customizado a la necesidad de la empresa, de su volumen ' +
        'de operaciones o su cantidad de recursos humanos y tecnológicos.\n\n' +
        'Entre los servicios podemos mencionar:\n\n' +
        '■ Auditor Externo con matricula habilitada en la SSN.\n' +
        '■ Desarrollo de Políticas y Manuales en la Prevención del Lavado de dinero.\n' +
        '■ Desarrollo de Políticas y Manuales en la Prevención del Fraude.\n' +
        '■ Desarrollo de Políticas y Manuales en Auditoría y Control Interno.\n' +
        '■ Informe del Revisor Externo Independiente.\n' +
        '■ Desarrollo de Políticas y Manuales en Gobierno Corporativo.\n' +
        '■ Desarrollo de Políticas y Manuales en Gestión de Enfoque Basado en Riesgos.\n' +
        '■ Desarrollo de Políticas y Manuales en el Cumplimiento de la Ley Penal ' +
        'Empresaria.\n' +
        '■ Desarrollo de Presupuestos y Controles Presupuestarios – Tablero de comando y ' +
        'Cash-Flow.\n' +
        '■ Análisis de contratos de reaseguros y sugerencia de Mejoras.\n' +
        '■ Desarrollo de Nuevos Productos de Vida y Seguros Generales o Retiro.\n' +
        '■ Cálculo de necesidades de capital.\n' +
        '■ Desarrollo de una red comercial sustentable.\n' +
        '■ Capacitación y entrenamiento en formato presencial o en nuestro campus virtual.'
    },
  },
  'servicios-para-reaseguradoras': {
    sliders: [{
      title: 'Servicios para compañías de Reaseguros',
      image: CompaniaReaseguradora
    }],
    content: {
      title: 'Servicios para compañías de Reaseguros',
      text: 'Hemos desarrollado una variada gama de servicios para estas empresas. En todos los ' +
        'casos, brindamos un servicio customizado a la necesidad de la empresa, de su volumen ' +
        'de operaciones o su cantidad de recursos humanos y tecnológicos.\n\n' +
        'Entre los servicios podemos mencionar:\n\n' +
        '■ Auditor Externo con matricula habilitada en la SSN.\n' +
        '■ Desarrollo de Políticas y Manuales en la Prevención del Fraude.\n' +
        '■ Desarrollo de Políticas y Manuales en Auditoría y Control Interno.\n' +
        '■ Desarrollo de Políticas y Manuales en Gobierno Corporativo.\n' +
        '■ Desarrollo de Políticas y Manuales en Gestión de Enfoque Basado en Riesgos.\n' +
        '■ Desarrollo de Políticas y Manuales en el Cumplimiento de la Ley Penal ' +
        'Empresaria.\n' +
        '■ Desarrollo de Presupuestos y Controles Presupuestarios – Tablero de comando y ' +
        'Cash-Flow.\n' +
        '■ Cálculo de necesidades de capital.\n' +
        '■ Capacitación y entrenamiento en formato presencial o en nuestro campus virtual.'
    },
  },
  'servicios-para-brokers-de-seguros-y-reaseguros': {
    sliders: [{
      title: 'Servicios para broker de Seguros o Reaseguros',
      image: BrokersSeguros
    }],
    content: {
      title: 'Servicios para broker de Seguros o Reaseguros',
      text: 'Hemos desarrollado una variada gama de servicios para estas empresas. En todos los ' +
        'casos, brindamos un servicio customizado a la necesidad de la empresa, de su volumen ' +
        'de operaciones o su cantidad de recursos humanos y tecnológicos.\n\n' +
        'Entre los servicios podemos mencionar:\n\n' +
        '■ Auditor Externo con matricula habilitada en la SSN.\n' +
        '■ Desarrollo de Políticas y Manuales en la Prevención del Lavado de dinero.\n' +
        '■ Informe del Revisor Externo Independiente. \n' +
        '■ Desarrollo de Políticas y Manuales en Gestión de Enfoque Basado en Riesgos.\n' +
        '■ Desarrollo de Políticas y Manuales en el Cumplimiento de la Ley Penal ' +
        'Empresaria.\n' +
        '■ Desarrollo de Presupuestos y Controles Presupuestarios – Tablero de comando y ' +
        'Cash-Flow.\n' +
        '■ Análisis de contratos de reaseguros y sugerencia de Mejoras.\n' +
        '■ Desarrollo de Nuevos Productos de Vida y Seguros Generales o Retiro.\n' +
        '■ Cálculo de necesidades de capital.\n' +
        '■ Desarrollo de una red comercial sustentable.\n' +
        '■ Capacitación y entrenamiento en formato presencial o en nuestro campus virtual.'
    },
  },
  'consultoria-en-ciberseguridad': {
    sliders: [{
      title: 'Consultoría en Ciber-Seguridad',
      image: CiberSeguridad
    }],
    content: {
      title: 'Consultoría en Ciber-Seguridad',
      text: 'La realidad que estamos atravesando de Covid-19 no tiene precedentes y nos toma por ' +
        'sorpresa. Esta realidad empuja a la mayoría de las empresas a trabajar al 100% en la ' +
        'modalidad de teletrabajo o por lo menos que una gran parte de la organización trabaje a ' +
        'distancia. Esta modalidad, hace que los recursos informáticos se encuentren muy ' +
        'expuestos a ciberataques.\n\n' +
        'Los ciberdelincuentes ofrecen en la web desde medicamentos falsos para el tratamiento ' +
        'de COVID-19 hasta ataques complejos de ransomware, irrumpiendo videoconferencias, ' +
        'generando correos electrónicos fraudulentos de phishing, creando formularios falsos o ' +
        'insertando malware sobre los servicios “on-line” de los sistemas de salud, banca o ' +
        'seguros, afectando su disponibilidad y desencadenando efectos catastróficos en el ' +
        'desarrollo de las actividades de la organización.\n\n' +
        'En este sentido hemos incorporado una serie de servicios de ciberseguridad que ' +
        'entendemos que son de extrema utilidad para las empresas de seguros del mundo que ' +
        'viene.\n\n' +
        'Algunos de estos servicios son:\n\n' +
        '■ Plan Estratégico de Seguridad / Ciberseguridad: Estrategia de seguridad, ' +
        'planificación presupuestaria de proyectos y mejora continua, en base a Estándares ' +
        'Internacionales.\n' +
        '■ Virtual CISO: Outsourcing del rol del Responsable de Seguridad y del Área ' +
        'Seguridad Informática / Ciberseguridad. Obtención de indicadores y seguimiento ' +
        'de resultados conjuntamente con el Área de Sistemas.\n' +
        '■ Marco Normativo en Seguridad: Elaborar la Política General de Seguridad y un ' +
        'conjunto de documentos normativos que permitan cumplir regulaciones y ' +
        'acompañar al negocio.\n' +
        '■ Plan de Continuidad del Negocio: Plan que permite –frente a la ocurrencia de un ' +
        'incidente grave- mantener la operación de la organización a un nivel mínimo ' +
        'aceptable, recuperando de forma gradual el negocio.\n' +
        '■ Ciberseguros para Organizaciones: Análisis de riesgos que permite evaluar ' +
        'rápidamente los riesgos a los que está expuesta la organización y poder ' +
        'determinar la cobertura adecuada frente a ciberataques que provoquen pérdidas ' +
        'significativas.\n' +
        '■ Evaluación de Vulnerabilidades en Sistemas y Apps: Realización de tests de ' +
        'intrusión y análisis de vulnerabilidades internos y externos, simulando la acción de ' +
        'un ciberatacante.'
    },
  }
}
