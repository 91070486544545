export default {
  HOME: '/',
  PARTNERS: '/partners',
  ERROR: '/error',
  PUBLICATIONS: '/publicaciones',
  PUBLICATION: '/publicaciones/:id',
  CLIENTS: '/clientes',
  CONTACT: '/contact',
  OUR_US: '/quienes-somos',
  SERVICES: '/servicios',
  TRAINING: '/capacitacion',
  INDUSTRY: '/industria',
}
