import React from 'react';
import { connect } from "react-redux";

import Partners from './';

const PartnersContainer = props => (
  <Partners
    partners={props.partners}
  />
);

const mapStateToProps = state => {
  return {
    partners: state.partners,
  }
};

export default connect(mapStateToProps, {})(PartnersContainer)
