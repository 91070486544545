import Seguros from "../../assets/images/services/compania-seguros.jpg";
import Reaseguros from "../../assets/images/services/companias-reaseguradoras.jpg";
import BilleteraVirtual from "../../assets/images/industry/billetera-virtual.jpg";
import Salud from "../../assets/images/industry/salud.jpg"
import Asistencia from "../../assets/images/services/asistencia.jpg";
import Cooperativas from "../../assets/images/services/cooperativas.jpg";
import Car from "../../assets/images/industry/ar.jpg";
import Sindicatos from "../../assets/images/industry/sindicate.jpg";
import BrokageSeguros from "../../assets/images/services/brokers-seguros.jpg";

export default {
  'seguros': {
    sliders: [{
      title: 'SEGUROS',
      image: Seguros
    }],
    content: {
      title: 'SEGUROS',
      text: 'La industria aseguradora ha tenido un particular crecimiento en todo Latinoamérica, con ' +
        'mercados que potencialmente pueden crecer mucho más cuando se analiza el consumo ' +
        'per cápita de la población. A diferencia del mercado americano o europeo, está todo por ' +
        'hacer y crecer en la rama de personas. Por eso, apostamos en su crecimiento e invertimos ' +
        'en productos que hagan más fácil la comercialización de seguros de personas.\n\n' +
        'Pensamos, creemos e invertimos en la capacitación de las personas para que el seguro ' +
        'sea una herramienta de confianza y resguardo de las personas. \n\n' +
        'En Assesorum apostamos por ello de manera permanente, diseñamos software que ' +
        'simplifiquen la vida de las aseguradoras, los PAS /Bróker y otros canales ' +
        'comercializadores para que, en definitiva, la gente esté asegurada verdaderamente.\n\n' +
        'Nuestra experiencia de más de 30 años en el mercado de seguros nos permite ' +
        'comprender los problemas que son comunes para los players del mercado y anticiparnos a ' +
        'sus problemas. Nuestros profesionales están altamente motivados y además son ' +
        'capacitadores de capacitadores',
    },
  },
  'reaseguros': {
    sliders: [{
      title: 'REASEGUROS',
      image: Reaseguros
    }],
    content: {
      title: 'REASEGUROS',
      text: 'La complejidad de este tipo de empresas en un mundo cada vez más cambiante ' +
        'y expuesto a múltiples riesgos: climáticos y catastróficos, globales, políticos, económicos y de ' +
        'toda índole. Pensamos que podemos ayudarlos con la planificación estratégica, la prevención ' +
        'en delitos de ciber-seguridad y en hacer de su entorno un lugar más predecible y ' +
        'económicamente más equilibrado.',
    },
  },
  'brokerage-de-seguros': {
    sliders: [{
      title: 'BROKERAGE DE SEGUROS',
      image: BrokageSeguros
    }],
    content: {
      title: 'BROKERAGE DE SEGUROS',
      text: 'Segmento clave de la economía de cualquier País. Son ' +
        'profesionales, ya sean personas humanas o jurídicas que con su conocimiento, capacidad y ' +
        'buenas prácticas asesoran a los clientes en las mejores coberturas en las aseguradoras y los ' +
        'asisten al momento de tener que enfrentarse con un siniestro.',
    },
  },
  'brokerage-de-reaseguros': {
    sliders: [{
      title: 'BROKERAGE DE REASEGUROS',
      image: BrokageSeguros
    }],
    content: {
      title: 'BROKERAGE DE REASEGUROS',
      text: 'Segmento especializado de asesores que intermedian las ' +
        'operaciones entre aseguradoras y reaseguradores. Con su especialidad y su conocimiento ' +
        'aportan eficiencia, buenas prácticas y dedicación en la perfección y la búsqueda del mejor ' +
        'contrato. ' +
        'Podemos asistirlos en toda la planificación estratégica, la búsqueda de resultados y la ' +
        'eficiencia de su negocio.',
    },
  },
  'sindicatos': {
    sliders: [{
      title: 'SINDICATOS',
      image: Sindicatos
    }],
    content: {
      title: 'SINDICATOS',
      text: 'Sector de la economía que brinda asistencia a sus afiliados de base. Buscando ' +
        'nuevas prestaciones y mejoras, nuevos servicios, descuentos, atención médica y farmacéutica ' +
        'o beneficios en turismo.\n\n' +
        'Hemos asistido a varios Sindicatos de diferentes maneras: colaborando en la formación de una ' +
        'Aseguradora de Riesgos del Trabajo, asesorándolos en productos de asistencia o vendiendo ' +
        'software a medida que les permite conocer más su gestión administrativa y su padrón de ' +
        'afiliados y el estado de cobranzas y deudas de las empresas afiliadas.',
    },
  },
  'concecionarias-de-autos': {
    sliders: [{
      title: 'CONCECIONARIO DE AUTOS',
      image: Car
    }],
    content: {
      title: 'CONCECIONARIO DE AUTOS',
      text: 'Sector de la economía que maneja un volumen importante de ' +
        'operaciones y dinero. Altamente desarrollado y competitivo.\n\n' +
        'Nuestro equipo tiene como objetivo ofrecer una oferta de servicios integrales y completos, ' +
        'para proporcionar asesoramiento financiero, desde fusiones y adquisiciones y prevención del ' +
        'lavado de activos y el financiamiento del terrorismo.',
    },
  },
  'cooperativas-y-mutuales': {
    sliders: [{
      title: 'COOPERATIVAS Y MUTUALES',
      image: Cooperativas
    }],
    content: {
      title: 'COOPERATIVAS Y MUTUALES',
      text: 'Son empresas sin fines de lucro, gestionadas por sus usuarios, ' +
        'cuyo objetivo es garantizar el acceso a los servicios en condiciones de equidad y transparencia, ' +
        'mediante una gestión democrática. Nuestro país cuenta con miles de estas organizaciones a lo ' +
        'ancho y a lo largo del mismo y son un motor clave de la economía de cualquier región.\n\n' +
        'Nuestro equipo apunta a ofrecer una oferta de servicios integrales y completos que suscriptos ' +
        'por las cooperativas o mutuales les permitan a sus asociados beneficiarse en la amplitud de servicios. ' +
        'Entre ellos, seguros, salud, asistencia en sus formas más variadas y con las mejores ' +
        'ofertas de calidad y precio del mercado. ' +
        'De esta manera, se cumpla la misión de este tipo de empresas que, sostenidas por el aporte de ' +
        'sus asociados, actuando con seriedad y eficiencia de empresas, se reúne para solucionar ' +
        'problemas comunes',
    },
  },
  'mercado-de-asistencia': {
    sliders: [{
      title: 'MERCADOS DE ASISTENCIA',
      image: Asistencia
    }],
    content: {
      title: 'MERCADOS DE ASISTENCIA',
      text: 'Mercado pujante de la economía que, conforman un grupo de empresas nacionales o ' +
        'internacionales cuyo principal negocio es ofrecer una gama de productos/servicios para ' +
        'solucionar la vida de las personas en general o de grandes colectivos en particular. \n\n' +
        'Los más comunes son la asistencia en viajes nacional o internacional o la gruja que remolca el ' +
        'carro en una avería o siniestro.\n\n' +
        'Existe toda otra gama de productos/servicios que nuestros equipos especializados pueden ' +
        'asesorar a personas humanos o grandes colectivos de personas (cooperativas, mutuales, ' +
        'sindicatos, aseguradoras, etc). Nuestra experiencia y versatilidad permite asesorar en la ' +
        'búsqueda de la mejor opción.',
    },
  },
  'tarjetas-de-credito-y-billeteras-virtuales': {
    sliders: [{
      title: 'TARJETAS DE CREDITOS Y BILLETERAS VIRTUALES',
      image: BilleteraVirtual
    }],
    content: {
      title: 'TARJETAS DE CREDITOS Y BILLETERAS VIRTUALES',
      text: 'Mercado con un crecimiento exponencial y actualmente base de las nuevas Fintech. Son parte ' +
        'de la industria financiera que aplicado a nuevas tecnologías facilita las actividades financieras y ' +
        'de inversión.\n\n' +
        'Los servicios más utilizados por los usuarios son: préstamos, pagos y transferencias y servicios ' +
        'B2B (business to business), es decir, soluciones a comercios, pymes y grandes empresas a ' +
        'través de un modelo de ingresos con predominancia de software. Están también las de banca e ' +
        'inversiones, insurtech (seguros), blockchain &amp; crypto, financiamiento colectivo y seguridad ' +
        'informática.\n\n' +
        'Nuestros equipos pueden colaborar en temas de prevención del lavado de activos y ' +
        'financiamiento del terrorismo, ciber-seguridad o prevención del fraude. Nuestra experiencia y ' +
        'versatilidad permite asesorar en la búsqueda de la mejor opción.',
    },
  },
  'salud': {
    sliders: [{
      title: 'SALUD',
      image: Salud
    }],
    content: {
      title: 'SALUD',
      text: 'Sector clave de la economía en tiempos actuales y futuros. La búsqueda de la población ' +
        'para la cobertura de salud, es, fue y será un problema en todos los países del mundo. \n\n' +
        'Contamos con un equipo de profesionales con experiencia, visión de futuro que ofrecen ' +
        'asesoramiento especializado, capaz de dar respuesta a los requerimientos cada vez más ' +
        'exigentes.\n\n' +
        'Nuestro equipo podrá asesorarlo en el desarrollo de nuevos productos, nuevos servicios o ' +
        'mejora de los actuales. También tenemos expertos médicos especializados en el tema que ' +
        'están preparados para hacer auditorías médicas para asesorarlo en como optimizar su ' +
        'clínica o sanatorio.\n\n' +
        'Búsquedas de nuevas prácticas o mejoras de las mismas, capacitación de los cuadros, ' +
        'mejoras de procesos todo ligado a la nueva tecnología inteligente.',
    },
  },
}
