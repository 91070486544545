import React from 'react';
import classNames from "classnames";
import uuid from "react-uuid";
import { Link } from "react-router-dom";

const Menu = props => {
  const { menu, handleItem } = props;

  return (
    <ol className="header__menu">
      {
        menu.map((item, index) => {
          const itemIndex = index;
          let iconClasses = classNames('fa', {
            'fa-plus': !item.active,
            'fa-minus': item.active,
          });
          let menuClasses = classNames('menu__subcategory-menu', {
            'menu__subcategory-menu--active': item.active
          });

          return (
            <div className="menu__item-clickable" key={uuid()}>
              {
                item.link
                  ? <Link to={item.link} >
                    <li className="menu__category" key={uuid()}>
                      {
                        item.children &&
                        <i className={iconClasses} aria-hidden="true" onClick={() => handleItem(itemIndex)}/>
                      }
                      <span>{item.name}</span>
                    </li>
                  </Link>
                  :
                    item.ancle
                      ? <a href={item.ancle}>
                          <li className="menu__category" key={uuid()}>
                            <span>{item.name}</span>
                          </li>
                        </a>
                      : <li className="menu__category" key={uuid()}>
                        {
                          item.children &&
                          <i className={iconClasses} aria-hidden="true" onClick={() => handleItem(itemIndex)}/>
                        }
                        <span>{item.name}</span>
                      </li>

              }
              {
                (item.children && item.children.length > 0) && <ol className={menuClasses}>
                  {
                    item.children.map((subitem, subitemIndex) => {
                      let iconClasses = classNames('fa', {
                        'fa-plus': !subitem.active,
                        'fa-minus': subitem.active,
                      });
                      let menuClasses = classNames('menu__subcategory-children-menu', {
                        'menu__subcategory-children-menu--active': subitem.active
                      });

                      return (
                        <div className="submenu__content" key={uuid()}>
                          <li className="menu__category" key={uuid()}>
                            {subitem.children && <i
                              className={iconClasses}
                              aria-hidden="true"
                              onClick={() => handleItem(itemIndex, subitemIndex)}
                            />}
                            {
                              (subitem.path && !subitem.noLink)
                                ? <span><Link to={`${item.path}${subitem.path}`}>{subitem.name}</Link></span>
                                : <span>{subitem.name}</span>
                            }
                          </li>
                          {
                            (subitem.children && subitem.children.length > 0) &&
                            <ol className={menuClasses}>
                              {
                                subitem.children.map((i) => (
                                  <li className="menu__category" key={uuid()}>
                                    <Link to={item.path + subitem.path + i.path}>
                                      <span>{i.name}</span>
                                    </Link>
                                  </li>
                                ))
                              }
                            </ol>
                          }
                        </div>
                      )
                    })
                  }
                </ol>
              }
            </div>
          )
        })
      }
    </ol>
  )
};

export default Menu;
