import administrativos from './wordings/administrativos';
import gestion from './wordings/gestion';
import consultoria from './wordings/consultoria';
import comerciales from './wordings/comerciales';
import asistencia from './wordings/asistencia';

export default {
  administrativos,
  gestion,
  consultoria,
  comerciales,
  asistencia,
}
