import React from 'react';
import PropTypes from 'prop-types';
import SlickSlider from "react-slick";
import classNames from 'classnames';
import uuid from "react-uuid";
import { Oval } from "svg-loaders-react";
import { Link } from "react-router-dom";

const Slider = props => {
  const { sliders, publication } = props;
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const content = publication ? [publication]: sliders;
  const sliderContentClasses = classNames('slider__content', { 'slider__content--in-publication': publication });

  return (
    <section className="home__slider">
      <SlickSlider {...settings}>
        {
          (content && content.length > 0)
            ? content.map(slider => (
              <div className="slider__slide" key={uuid()}>
                <figure className="slider__bg" >
                  <div className="slider__filter" />
                  <img src={slider.image} alt={slider.title} />
                </figure>
                <section className={sliderContentClasses}>
                  <div className="slider__content-info">
                    <div className="slider__decoration-top" />
                    <h2>{slider.title}</h2>
                    {slider.subtitle && <p>{slider.subtitle}</p>}
                    {slider.button && <Link to={slider.link}><div className="slider__button">{slider.button}</div></Link>}
                    <div className="slider__decoration-bottom" />
                  </div>
                </section>
              </div>
            ))
            : <section className="home__slider-loading">
            <Oval />
          </section>
        }
      </SlickSlider>
    </section>
  )
};

Slider.propTypes = {
  publication: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
    }),
  ),
};


export default Slider;
