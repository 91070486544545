import GobiernoCorporativoImage from './../../../assets/images/services/gobierno-corporativo.jpg';
import PrevencionDelLavadoDeDineroImage from './../../../assets/images/services/prevencion-lavado-de-dinero.jpeg';
import ControlInternoParaAseguradorasImage from './../../../assets/images/services/control-interno.jpg';
import PrevencionDelFraudeImage from './../../../assets/images/services/prevencion-del-fraude.jpeg';
import ManualesDeProcesosImage from './../../../assets/images/services/manuales-de-procesos.jpg';
import FlujogramasImage from './../../../assets/images/services/flujograma.jpg';

export default {
  'gobierno-corporativo': {
    sliders: [{
      title: 'Gobierno Corporativo',
      image: GobiernoCorporativoImage
    }],
    content: {
      title: 'Gobierno Corporativo',
      text: 'A través de la gestión de riesgos, buscamos ayudar a las organizaciones a mejorar sus ' +
        'procesos internos y de esta manera lograr una mayor trasparencia para los clientes, ' +
        'proveedores, empleados y el gobierno:\n\n' +
        '■ Órganos de administración más transparentes en relación con sus antecedentes y ' +
        'sus conocimientos.\n' +
        '■ Publicación de Balances y Resultados.\n' +
        '■ Auditoría interna.\n' +
        '■ Auditoría y monitoreo continuo mediante el uso de tecnología.\n' +
        '■ Creación o mejora de tableros de control.\n' +
        '■ Gestión de riesgos.\n' +
        '■ Cumplimiento normativo - regulatorio.\n' +
        '■ Implementación de pautas de comportamiento (ética y conducta).\n' +
        '■ Cumplimiento de lo referido a protección de datos personales.\n' +
        '■ Control y seguimiento de proveedores.\n' +
        '■ Automatización y control de anticipo y rendición de gastos.'
    },
  },
  'prevencion-lavado-de-dinero': {
    sliders: [{
      title: 'Prevención del Lavado de Dinero',
      image: PrevencionDelLavadoDeDineroImage
    }],
    content: {
      title: 'Prevención del Lavado de Dinero',
      text: 'Servicio ofrecido a los sujetos obligados que determinan las Leyes vigentes y la Unidad de ' +
        'Información Financiera. Utilizando el enfoque basado en riesgos que requiere la normativa, ' +
        'buscamos ayudar a las organizaciones a mejorar sus procesos, reducir los riesgos de ser ' +
        'utilizados como vehículos para lavar dinero y de esta manera cumplir las leyes vigentes. \n' +
        'Nuestros servicios abarcan:\n\n' +
        '■ Informe del Revisor Externo Independiente ya que estamos certificados por la ' +
        'Unidad de Información Financiera.\n' +
        '■ Diseño de Planes anuales de Prevención Lavado de activos y Financiamiento del ' +
        'Terrorismo.\n' +
        '■ Capacitación en formato presencial o en la modalidad e-learning en nuestro ' +
        'campus virtual.\n' +
        '■ Desarrollo o actualización de Manuales de Procedimientos.\n' +
        '■ Flujogramas.\n' +
        '■ Auditorías de gestión.\n' +
        '■ Revisión o creación de formularios adecuados.\n' +
        '■ Generación o Revisión de Matriz de Riesgos para clientes.\n' +
        '■ Generación o Revisión de Matriz de Riesgos por procesos.'
    },
  },
  'control-interno': {
    sliders: [{
      title: 'Control Interno para Aseguradoras',
      image: ControlInternoParaAseguradorasImage
    }],
    content: {
      title: 'Control Interno para Aseguradoras',
      text: 'Servicio ofrecido para las compañías de seguros con el objeto de dar cumplimiento a las ' +
        'regulaciones de la Superintendencia de Seguros de la Nación. \n\n' +
        '■ Realizar el Plan Anual de Control Interno (PACI),\n' +
        '■ Revisar los Procedimientos Mínimos Obligatorios.\n' +
        '■ Elaboración y actualización de los Manuales de Procedimientos respectivos.\n' +
        '■ Revisión y elaboración de flujogramas.\n' +
        '■ Auditoría y revisión de los procesos.\n' +
        '■ Capacitación al personal en formato presencial o en nuestro campus virtual.\n' +
        '■ Considerar en la evaluación la Res. UIF No.156/18 (cumplimiento de las tareas de ' +
        'la PLAFT) según lo estipulado en el Artículo 19 inciso a) de la mencionada norma.\n' +
        '■ Considerar en la evaluación la Res. SSN No.38477/14 (cumplimiento de las tareas ' +
        'de Fraude).'
    },
  },
  'prevencion-del-fraude': {
    sliders: [{
      title: 'Prevención del fraude',
      image: PrevencionDelFraudeImage
    }],
    content: {
      title: 'Prevención del fraude',
      text: 'El fraude a las aseguradoras en un problema a nivel mundial. Tener un buen sistema de ' +
        'prevención del fraude tanto interno como externo es fundamental. Ayuda a reducir costos ' +
        'ocultos y disuade al potencial defraudador.\n\n' +
        '■ Confección del Plan Anual de Prevención del Fraude.\n' +
        '■ Revisión del Proceso actual de prevención del fraude y sugerencias de mejoras.\n' +
        '■ Seguimiento y monitoreo del Plan Anual.\n' +
        '■ Diseño de Reportes, alertas y alarmas.\n' +
        '■ Revisión y emisión del informe anual obligatorio.'
    },
  },
  'manuales-de-procesos': {
    sliders: [{
      title: 'Manuales de Procesos',
      image: ManualesDeProcesosImage
    }],
    content: {
      title: 'Manuales de Procesos',
      text: 'Nuestro servicio apunta a lograr que las empresas tengan sus procesos actualizados, libre ' +
        'de fallos y fraudes y lo más eficiente posible. La manualización busca evitar errores, ' +
        'deficiencias y demoras en los procesos que incrementen los costos o reduzcan los ' +
        'resultados.\n\n' +
        '■ Manuales de Productos.\n' +
        '■ Manuales de Control Interno.\n' +
        '■ Manuales de Gestión de Riesgos.\n' +
        '■ Manuales de Planes de Recuperación.\n' +
        '■ Códigos de Conducta o Ética.\n' +
        '■ Manuales de Prevención de Lavado de activos.\n' +
        '■ Manuales de Prevención del Fraude.\n' +
        '■ Códigos de Gobierno Corporativo.\n' +
        '■ Manuales de Cumplimiento.\n' +
        '■ Manuales de Ley Penal Empresaria y sus procedimientos de cumplimiento.'
    },
  },
  'flujogramas': {
    sliders: [{
      title: 'Flujogramas',
      image: FlujogramasImage
    }],
    content: {
      title: 'Flujogramas',
      text: 'Creemos en la importancia de los flujogramas como una parte importante y eficiente de ' +
        'describir los procesos, mejorarlos, reducir tiempos e ineficiencias y a través de un rápido ' +
        'vistazo, detectar errores o potenciales fraudes. Un flujograma, también denominado ' +
        'diagrama de flujo, es una muestra visual de una línea de pasos de acciones que implican ' +
        'un proceso determinado. Es decir, el flujograma consiste en representar gráficamente, ' +
        'situaciones, hechos, movimientos y relaciones de todo tipo a partir de símbolos. ' +
        'Alentamos que todos los manuales de procesos contengan los flujogramas que ' +
        'correspondan.'
    },
  },
}
